import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Select,
  Space,
  Tag,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import customAxios from "../../utils/axios";
import axios from "axios";

import { useLocation } from "react-router";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";
import Link from "antd/es/typography/Link";

const formItemLayout = null;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const InfluencerDetail = () => {
  const [loading, setLoading] = useState(true);

  const [isEdit, setIsEdit] = useState("");

  const location = useLocation();
  const influencerId = location.pathname.split("/")[2];
  const [linkType, setLinkType] = useState("IMAGE");
  const [usedFor, setUsedFor] = useState("Chatting");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [fileUploadId, setFileUploadId] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [linkPrefix, setLinkPrefix] = useState("http://");
  const [form] = Form.useForm();

  const [influencerLinks, setInfluencerLinks] = useState([]);

  const OpenEditRecord = (id) => {
    // Find the record in the array with the matching id
    const record = influencerLinks.find((record) => record.id === id);

    console.log(record);

    setIsEdit(record);
    form.setFieldsValue({
      tags: record.tags.map((tag) => tag),
      name: record.name,
      link: record.type == "IMAGE" ? "" : record.link.trim(),
      type: record.type,
    });

    setLinkType(record.type);
    setUsedFor(record.used_for);

    let recordTags = [];
    record.tags.map((tag) => {
      const uniqueId = Math.floor(Math.random() * 10000);
      recordTags.push({ value: tag, id: uniqueId });
    });

    setTags(recordTags);

    try {
      setFileUploadPath(record.link);
    } catch (error) {}

    setIsAddLinkModalOpen(true);
  };

  const OpenDeleteRecordAreYouSure = (id) => {
    // Find the record in the array with the matching id
    const record = influencerLinks.find((record) => record.id === id);

    // If there is a record, set the deleteActive property to true
    if (record) {
      record.deleteActive = true;
      setInfluencerLinks([...influencerLinks]);
    }
  };

  const CloseDeleteRecordAreYouSure = (id) => {
    // Find the record in the array with the matching id
    const record = influencerLinks.find((record) => record.id === id);

    // If there is a record, set the deleteActive property to true
    if (record) {
      record.deleteActive = false;
      setInfluencerLinks([...influencerLinks]);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const [data, setData] = useState([
    {
      id: "",
      name: "",
      lora_model_name: "",
      server_ip: "",
      character: "",
      voice_id: "",
      voice_id_2: "",
      voice_model_id: "",
      voice_model_id_2: "",
      prohibited_topics: "",
      temperature: "",
      top_p: "",
      top_k: "",
      min_length: "",
      max_new_tokens: "",
      notification_frequency: "",
      notification_message: "",
    },
  ]);

  const getNormalizedTags = (txt) => {
    return txt?.toString() ?? "";
  };

  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);

  const determineLinkType = (link, type) => {
    if (type === "IMAGE") {
      return (
        <img
          alt={link}
          style={{ width: "50px", height: "auto" }}
          src={link}
        ></img>
      );
    } else if (type === "WEBLINK") {
      return (
        <Link href={link} target="_blank">
          {link}
        </Link>
      );
    } else {
      <video src={link}></video>;
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text) => <div>{text || "-"}</div>,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => <div>{text || "-"}</div>,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text) => <div>{getNormalizedTags(text)}</div>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <div>
          {text?.toString().toLowerCase() === "image"
            ? "Image(EC2)"
            : "Web Link" || ""}
        </div>
      ),
    },
    {
      title: "Preview",
      dataIndex: "link",
      key: "link",
      render: (text, values) => (
        <div>{determineLinkType(text, values.type)}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Popconfirm
            open={record.deleteActive}
            title="Are you sure？"
            okText="Delete"
            onConfirm={() => deleteRecord(record)}
            onCancel={() => CloseDeleteRecordAreYouSure(record.id)}
            cancelText="No"
          ></Popconfirm>
          <Button
            type="primary"
            danger
            onClick={() => OpenDeleteRecordAreYouSure(record.id)}
          >
            <DeleteOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => OpenEditRecord(record.id)}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const influencerColumns = [
    {
      title: "UUID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Server Ip",
      dataIndex: "server_ip",
      key: "server_ip",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Character",
      dataIndex: "character",
      key: "character",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voice Id",
      dataIndex: "voice_id",
      key: "voice_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voice Id 2 (for Elevenlabs API Key 2)",
      dataIndex: "voice_id_2",
      key: "voice_id_2",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Open AI Model",
      dataIndex: "open_ai_model",
      key: "open_ai_model",
      render: (text) => <div>{text}</div>,
    },
    /*{
     title: "Prohibited Topics",
     dataIndex: "prohibited_topics",
     key: "prohibited_topics"
    },*/
    {
      title: "Profile Picture",
      dataIndex: "profilePic",
      key: "profilePic",
      render: (text) => (
        <img
          src={text}
          alt="No Profile Pic"
          style={{ width: "50px", height: "auto" }}
        />
      ),
    },
  ];

  useEffect(() => {
    getUserData();
    getInfluencerLinks();

    setFileInput(null);
  }, []);

  useEffect(() => {
    setFileUploadId("");
  }, [fileInput]);

  const getUserData = async () => {
    await customAxios.get(SERVER_URL + "/influencer").then(function (res) {
      // Only keep the influencer with the id we are looking for
      const filteredData = res.data.filter(
        (influencer) => influencer.id == influencerId
      );
      setData(filteredData);

      setLoading(false);
    });
  };

  useEffect(() => {
    setFileUploadId("");
  }, [fileInput]);

  const deleteRecord = async (record) => {
    try {
      const { data } = await customAxios.delete(
        SERVER_URL + "/influencer/links/" + influencerId,
        {
          data: {
            id: record.id,
          },
        }
      );
      setInfluencerLinks(data);
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  /*const uploadImageHandler = async () => {
    if (!fileInput) {
      messageApi.error("Invalid file!");
      return;
    }
    try {
      setFileUploadLoading(true);

      const arr = fileInput.name.split(".");
      const fileExt = arr[arr.length - 1];

      // get upload url
      const { data } = await customAxios.get(SERVER_URL + "/upload/url?ext=" + fileExt);

      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(fileInput);

        reader.onload = async (e) => {
          const imageData = new Uint8Array(e.target.result);
          await axios.put(data.url, imageData, {
            headers: {
              "Content-Type": fileInput.type
            }
          });

          setFileUploadId(data.file_id);
          resolve("--UPLOADED--");
        };
      });
      await promise
        .then((data) => console.log(data))
        .catch((err) => {
          throw new Error(err.message);
        });
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message
      });
    } finally {
      setFileUploadLoading(false);
    }
  };*/

  const uploadImageHandler = async () => {
    if (!fileInput) {
      messageApi.error("Invalid file!");
      return;
    }
    try {
      setFileUploadLoading(true);

      // Create a new FormData instance
      const formData = new FormData();

      // Append the file to the FormData instance
      formData.append("file", fileInput);
      formData.append("name", fileInput.name);
      formData.append("type", fileInput.type);

      // POST the formData instance to the server
      let response = await customAxios.post(
        SERVER_URL + "/upload/url",
        formData,
        {
          headers: {
            "Content-Type": fileInput.type,
          },
        }
      );

      // The file upload is complete
      setFileUploadPath(response.data.file_path);
      messageApi.success("File upload success!");
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    } finally {
      setFileUploadLoading(false);
    }
  };

  const handleTagClose = (id) => {
    const newTags = tags.filter((tag) => tag.id !== id);
    setTags([...newTags]);
  };

  const addNewTag = () => {
    const uniqueId = Math.floor(Math.random() * 100);
    if (tagInput !== "") {
      setTags([...tags, { value: tagInput, id: uniqueId }]);
      setTagInput("");
    }
  };

  const getInfluencerLinks = async () => {
    try {
      const { data } = await customAxios.get(
        SERVER_URL + "/influencer/links/" + influencerId
      );
      setInfluencerLinks(data);
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  const showSendMsgModal = () => {
    setIsAddLinkModalOpen(true);
  };

  const handleAddLinkOk = async () => {
    if (linkType === "IMAGE" && fileUploadPath == "") {
      messageApi.error("Please Upload the Image file first");
      return;
    }

    try {
      if (isEdit != "") {
        // Delete old record before adding new if it is edit
        deleteRecord(isEdit);

        setIsEdit("");
      }

      const formData = form.getFieldsValue();

      const body = {
        tags: tags.map((tag) => tag.value),
        name: formData.name ? formData.name.trim() : "",
        link: linkType === "IMAGE" ? fileUploadPath : formData.link?.trim(),
        type: linkType,
        used_for: usedFor,
      };
      const { data } = await customAxios.post(
        SERVER_URL + "/influencer/links/" + influencerId,
        body
      );
      setInfluencerLinks(data);
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }

    form.setFieldsValue({
      link: "",
      name: "",
    });
    setTags([]);
    setFileUploadId("");
    setFileUploadPath("");
    setFileInput(null);
    setTagInput("");
    setIsAddLinkModalOpen(false);
  };

  const handleAddLinkCancel = () => {
    setTags([]);
    setFileUploadId("");
    setFileUploadPath("");
    setFileInput(null);
    setTagInput("");
    setIsAddLinkModalOpen(false);
  };

  return (
    <div>
      {contextHolder}

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div>
          <div>
            <Button
              type="primary"
              style={{ margin: "0 8px" }}
              onClick={() => {
                setLinkType("IMAGE");
                setLinkType("IMAGE");
                showSendMsgModal();
              }}
            >
              Add Image
            </Button>
            <Button
              type="primary"
              style={{ margin: "0 8px" }}
              onClick={() => {
                setLinkType("WEBLINK");
                setLinkType("WEBLINK");
                showSendMsgModal();
              }}
            >
              Add Link
            </Button>
            <Table
              style={{ marginTop: "8px" }}
              columns={influencerColumns}
              dataSource={data}
              rowKey="id"
              pagination={{
                position: ["topRight", "bottomRight"],
              }}
            />
          </div>

          <div>
            <Table
              style={{ marginTop: "8px" }}
              columns={columns}
              dataSource={influencerLinks}
              rowKey="id"
              pagination={{
                position: ["topRight", "bottomRight"],
              }}
            />
          </div>
        </div>
      )}

      <Modal
        title={
          linkType == "IMAGE"
            ? "Add Image"
            : linkType == "VIDEO"
            ? "Add Video"
            : linkType == "VAULT"
            ? "Add Vault"
            : "Add Link"
        }
        open={isAddLinkModalOpen}
        onOk={handleAddLinkOk}
        onCancel={handleAddLinkCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item {...formItemLayout} name="name" label="Name">
            <Input placeholder="Enter link name" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="type" label="Link Type">
            <Select
              placeholder="Select Link Type"
              defaultValue={linkType}
              onChange={(val) => {
                if (val !== "IMAGE" && usedFor === "Purchasing") {
                  setUsedFor("Chatting");
                  form.setFieldValue("used_for", "Chatting");
                }
                setLinkType(val);
              }}
              options={[
                ["WEBLINK", "Web Link"],
                ["VIDEO", "Video"],
                ["IMAGE", "Image(EC2)"],
              ].map((item) => ({ label: item[1], value: item[0] }))}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} name="used_for" label="Used For">
            <Select
              placeholder="Select Used For"
              defaultValue={usedFor}
              onChange={(val) => setUsedFor(val)}
              options={[
                { label: "Chatting", value: "Chatting" },
                { label: "Notifications", value: "Notifications" },
                {
                  label: "Purchasing",
                  value: "Purchasing",
                  disabled: linkType !== "IMAGE",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="link"
            label={
              isEdit != "" && (linkType === "IMAGE" || linkType == "VIDEO")
                ? "Link (Current File: " + fileUploadPath + ")"
                : linkType === "VAULT"
                ? "Command"
                : "Link"
            }
          >
            {linkType === "IMAGE" || linkType == "VIDEO" ? (
              <Space.Compact block>
                <Input
                  type="file"
                  placeholder="Select file"
                  accept="image/*"
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      setFileInput(e.target.files[0]);
                    }
                  }}
                  defaultValue={null}
                />
                <Button
                  type="primary"
                  onClick={uploadImageHandler}
                  style={{ width: "20%" }}
                  loading={fileUploadLoading}
                  disabled={fileUploadId}
                >
                  {fileUploadId ? "Uploaded" : "Upload"}
                </Button>
              </Space.Compact>
            ) : (
              /* addonBefore={<SelectBefore linkPrefix={linkPrefix} setLinkPrefix={setLinkPrefix} />}  */
              <Input placeholder="link" />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} name="tags" label="Tags">
            <Space size="small" direction="vertical" style={{ width: "100%" }}>
              <Space.Compact block>
                <Input
                  placeholder="Add Tags"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  style={{ width: "80%" }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      addNewTag();
                    }
                  }}
                />
                <Button
                  type="primary"
                  onClick={addNewTag}
                  style={{ width: "20%" }}
                >
                  Add Tag
                </Button>
              </Space.Compact>
              <Space size={[0, 8]} wrap>
                {tags.length > 0 &&
                  tags.map((tag) => (
                    <Tag
                      key={tag.id}
                      closable
                      onClose={() => handleTagClose(tag.id)}
                    >
                      {tag.value}
                    </Tag>
                  ))}
              </Space>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default InfluencerDetail;

const SelectBefore = (props) => {
  return (
    <Select
      defaultValue="http://"
      value={props.linkPrefix}
      onChange={(val) => props.setLinkPrefix(val)}
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  );
};
